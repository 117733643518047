import axios from 'axios'
import {AuthModel, UserModel} from './_models'
import { instance } from '../../../../configs/axiosClient'
import { ID } from '../../../../_metronic/helpers'
import { FilterOptionsModel } from '../../statistics/Core/models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}Authentication/GetUserByToken/`
export const LOGIN_URL = `${API_URL}Authentication/Login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return instance.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return instance.post(REGISTER_URL, {
    email,
    firstName: firstname,
    lastName: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return instance.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return instance.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}


export function getSpacesInGroup(spaceGroupId: ID) {
  return instance.get<FilterOptionsModel[]>(`${API_URL}spaces/GetSpacesBySpaceGroupIdFilter/${spaceGroupId}`)
}
export function getEmployeesInGroup(spaceGroupId: ID) {
  return instance.get<FilterOptionsModel[]>(`${API_URL}employees/GetAllEmployesBySpaceGroupIdFilter/${spaceGroupId}`)
}
export function getSectionsInGroup(spaceGroupId: ID) {
  return instance.get<FilterOptionsModel[]>(`${API_URL}services/GetSectionsBySpaceGroupIdFilter/${spaceGroupId}`)
}
export function getGroupsInGroup(spaceGroupId: ID) {
  return instance.get<FilterOptionsModel[]>(`${API_URL}services/GetGroupsBySpaceGroupIdFilter/${spaceGroupId}`)
}


export function getEmployeesInSpace(spaceId: ID) {
  return instance.get<FilterOptionsModel[]>(`${API_URL}employees/GetAllEmployesBySpaceIdFilter/${spaceId}`)
}
export function getSectionsInSpace(spaceId: ID) {
  return instance.get<FilterOptionsModel[]>(`${API_URL}services/GetSectionsBySpaceIdFilter/${spaceId}`)
}
export function getGroupsInSpace(spaceId: ID) {
  return instance.get<FilterOptionsModel[]>(`${API_URL}services/GetGroupsBySpaceIdFilter/${spaceId}`)
}